import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usatbestfoodyet from '../assets/images/usatbestfoodyet.jpg';
import Sarahatregister from '../assets/images/sarahatregister.jpg';
import Christyatstove2 from '../assets/images/christyatstove2.jpg';
import Hhrefrigde from '../assets/images/hhrefrigde.jpg';
import Hhoutside2 from '../assets/images/hhoutside2.jpg';
import Cady from '../assets/images/cady.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Oxford: 2-26-01" />
      <div>
        <TripHeader>
          <h2>Oxford</h2>
          <p>1-26-01</p>
          <img
            src={Usatbestfoodyet}
            alt="Pooh, Bailey, Erin, Patrick, & Winter"
          />
          <ImageCaption>Pooh, Bailey, Erin, Patrick, & Winter</ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Sarahatregister} alt="Sarah at Register" />
            <ImageCaption>Sarah at Register</ImageCaption>
          </div>
          <div>
            <img src={Christyatstove2} alt="Christy at the Stove" />
            <ImageCaption>Christy at the Stove</ImageCaption>
          </div>
          <div>
            <img src={Hhrefrigde} alt="Huddle House Refrigerator" />
            <ImageCaption>Huddle House Refrigerator</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          We went to the Huddle House last Friday night and it was a blast. We
          took two new people who want to join the fan club. Winter Hicks and
          Erin Jordon joined us for the first time. Michael Wayne, Pooh, Bailey,
          and Patrick also went on this one. We didn't have time to eat, but we
          took pictures and said hey to the people on duty. Sarah, Christy,
          Joann, Janis, and Brandon were there. They were very nice and all
          signed a Huddle House napkin for me! It was a great trip, too bad we
          couldn't stay long.
        </p>
        <ImageGrid>
          <div>
            <img src={Hhoutside2} alt="Outside" />
            <ImageCaption>Outside</ImageCaption>
          </div>
          <div>
            <img src={Cady} alt="Caddy" />
            <ImageCaption>Caddy</ImageCaption>
          </div>
        </ImageGrid>
      </div>
    </>
  );
}
